<template>
    <div class="footer">
        <p>La información analizada en esta plataforma fue obtenida de los datos publicados por el IMPEPAC en su página web bajo el nombre "Lista de Candidatas y Candidatos Registrados Proceso Electoral Local 2020-2021"</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>
    .footer{
        width:70%;
        margin:20px auto;
    }
    .footer p{
        font-size:12px;
    }
</style>