<template>
  <div class="menu-paridad">
      <router-link to="/">Regresar</router-link>
  </div>
    <div 
    class="contenedor-partidos"
    v-for="(item, index) in data" :key="index"
    >
      <h1 class="titulo-grafica">{{item.partido}}</h1>
      <div class="grafica-horizontal">
        <PartidoGrafica titulo="Presidencia" 
        :hombres="item.presidencia.hombres"
        :mujeres="item.presidencia.mujeres"
        />
      </div>
       <div class="grafica-horizontal">
        <PartidoGrafica titulo="Sindicatura"
        :hombres="item.sindicaturas.hombres"
        :mujeres="item.sindicaturas.mujeres"
        />
      </div>
       <div class="grafica-horizontal">
        <PartidoGrafica titulo="Regiduría"  
          :hombres="item.regidurias.hombres"
          :mujeres="item.regidurias.mujeres"
        />
      </div>

      <Footer/>
    </div>

   
  
</template>
<script>

import PartidoGrafica from '../components/PartidosGrafica.vue'
import Footer from '../components/Footer.vue';
import { onMounted, ref } from 'vue';

export default {
  components: {
    PartidoGrafica,
    Footer
  },
  setup(){
    let data = ref(null);
     onMounted( () => {

            fetch('../DB/PartidoPolitico.json')
            .then(res => res.json())
            .then(data => mostrarDatos(data));

           
        })
      
    const mostrarDatos = (dato) => {
         data.value = dato;
    }


    return{
      data
    }

  }
}
</script>

<style scoped>
  .menu-paridad{
    width:70%;
    margin:10px auto;
    text-align:right;
  }

  .contenedor-partidos{
    width:100%;
    margin:10px auto;
    text-align:center;
    display:flex; justify-content:space-evenly; flex-wrap:wrap;
  }
  .titulo-grafica{
    width:100%;
  }
</style>