<template>
<!-- <p>Gráfica Presidente Suplente</p> -->
<div class="componente-grafica"> 
  <h4>{{titulo}}</h4>
  <column-chart 
  :data="[['Hombres',hombres],['Mujeres',mujeres]]"
  :colors="['#43e287', '#e28743']"
  ></column-chart>

  <p>{{leyenda}}</p>
</div>
  
</template>

<script>
export default {
  props:{
    hombres: { type: Number, default:0},
    mujeres: { type: Number, default:0},
    titulo: { type: String },
    leyenda: { type: String, default:"" }
  },

}
</script>

<style>
  .componente-grafica h4{
    text-align:left;
  }
</style>